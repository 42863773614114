import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import Hero from "../../../../components/hero";
import ContactPerson from "../../../../components/contactPerson";
import { CaseList } from "../../../../components/caseList.en";
import { Facts } from "../../../../components/facts";
import { Count } from "../../../../components/count";
import { Container } from "../../../../components/container";
import { ServicesTeaser } from "../../../../components/servicesTeaser";
import { PartnersGrid } from "../../../../components/partnersGrid";

const CoremediaPartnership = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-coremedia.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      coremediagold: file(relativePath: { eq: "partner/coremedia-gold.png" }) {
        ...pngTracedLogo
      }
      coremediacertified: file(
        relativePath: { eq: "partner/coremedia-certified.png" }
      ) {
        ...pngTracedLogo
      }
      nicole: file(relativePath: { eq: "profiles/nicole-reske.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }

    fragment pngTracedLogo on File {
      childImageSharp {
        gatsbyImageData(width: 380, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  `);

  return (
    <Layout lang="en" langPath="partner.coremedia.link">
      <Seo
        title="CoreMedia Implementation Partner – Monday Consulting"
        description={
          'As a "Gold and Certified Partner" for CoreMedia, we develop tailor-made platforms and integrate these into existing systems for large and mid-sized companies.'
        }
        keywords="CoreMedia, Implementation Partner, CMS, DXP, CMCC, Gold Partner, Headless CMS"
      />
      <div className="parallax-translate bg-shape-dots"></div>
      <Hero
        headline="CoreMedia – the intuitive Experience Platform"
        subline="Since 2009, we’ve focused on the smart CoreMedia DXP and implementing solutions that showcase its strengths."
        section="CoreMedia Partner"
        styleClass="mb-lg-5"
        bgColor="green"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="CoreMedia"
        />
      </Hero>

      <div className="container mb-4 mb-md-5 mt-md-5 my-5">
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-8 mb-4">
            <h2>Expertise that you can count on</h2>
            <p>
              CoreMedia’s flagship product is an agile content management system
              that facilitates the storage of content from a wide range of
              sources, and its deployment as an attractive digital experience
              across any device and any platform.
            </p>
            <p>
              From building tailor-made platforms for our customers to
              integrating these with existing systems, we’ve been working with
              this agile CMS as a "CoreMedia Gold Partner" since 2009. When it
              comes to developing the perfect solution, this content management
              system offers a maximum level of flexibility. From customer
              portals to staff extranets, our CoreMedia-based CMS solutions get
              information moving while networking the people and processes.
            </p>
            <p>
              As a "Certified Partner", we hold a full set of certificates for
              the complete CoreMedia platform, and are therefore ideally
              qualified to implement your solution professionally and customize
              it to your requirements. With our consultants and the CoreMedia
              CMS, you can face the future flexibly, confident that you will be
              able to reach your customers regardless of the touchpoint you want
              to serve with your system.
            </p>
          </div>
          <div className="col-12 col-md-4 px-4 d-flex flex-column align-items-center">
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.coremediagold.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="CoreMedia Gold Partner"
                title="CoreMedia Gold Partner"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={
                  images.coremediacertified.childImageSharp.gatsbyImageData
                }
                className="w-100"
                alt="CoreMedia Certified Partner"
                title="CoreMedia Certified Partner"
              />
            </div>
          </div>
        </div>
      </div>

      <Container shape="square">
        <div className="container mt-6 mb-4 mb-md-5">
          <h2 className="col-12 text-center">
            Good reasons for Monday Consulting and CoreMedia
          </h2>
          <Facts>
            <Count count="100" suffix="%">
              Complete set of CoreMedia certification.
            </Count>
            <Count count="2009">
              Longstanding CoreMedia partnership since 2009.
            </Count>
            <Count count="50" prefix=">">
              We’ve completed CoreMedia projects for over 50 satisfied
              customers.
            </Count>
          </Facts>
        </div>
      </Container>

      <div className="container">
        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-cross bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              1
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Best of breed</h2>
            <h3>Hybrid headless CMS</h3>
            <p>
              The CoreMedia Content Cloud lets you close the gap between
              marketing and development. The intuitive Studio user interface
              gives you a live preview for easy, error-free content editing. At
              the same time, this powerful headless solution also offers a wide
              range of the very latest CMS features. Examples here include
              advanced asset management plus GraphQL integration.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-dots bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              2
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Omnichannel-optimized
            </h2>
            <h3>Multi-experience orchestration</h3>
            <p>
              The CoreMedia Content Cloud was developed with the global
              omnichannel experience in mind. Using just a single system, you
              can manage your content while simultaneously repurposing it across
              all of your channels, regions and supported languages.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-circle bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              3
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Professional form management
            </h2>
            <h3>Formcentric for CoreMedia</h3>
            <p>
              Our Formcentric form manager integrates seamlessly with CoreMedia
              Studio. Extend your web presence to include professional HTML
              forms that blend effortlessly with your design. Letting you create
              a one-of-a-kind customer experience in no time at all.
            </p>
            <a
              href="https://formcentric.com/en/"
              target="_blank"
              className="btn mt-1"
              rel="noopener noreferrer"
            >
              More about Formcentric
            </a>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-square bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              4
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Advanced search for CoreMedia Studio
            </h2>
            <h3>Advanced Studio Search</h3>
            <p>
              Our Advanced Studio Search extension for CoreMedia Studio adds
              advanced search features and restores the kind of functionality
              familiar to earlier Site Manager users. Thanks to simple selection
              options and linked content properties, you can now run targeted
              and detailed searches across all your CMS content.
            </p>
            <a
              href="https://marketplace.coremedia.com/addon/advanced-studio-search-60482"
              target="_blank"
              className="btn mt-1"
              rel="noopener noreferrer"
            >
              More about our Advanced Studio Search
            </a>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Specialization for your success"
        teaser="With our strong partnerships, we create accessible and individual experiences of tomorrow."
      />

      <ServicesTeaser
        title="Discover our possibilities"
        text={
          "With our expertise in strategy and conception, we work together to create high-quality solutions that meet tomorrow's standards today.\nWe provide comprehensive services, encompassing the selection of the most suitable content management system, unique design and creation, and optimization of front-end, back-end, and infrastructure, all from a single source.\n\nWe guide you through the entire process and provide first-class customer care even after the project has been completed."
        }
        ctaLink="/en/services/serviceportfolio"
        ctaText="Our portfolio of services"
      />

      <div
        className="content-container mt-5 vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected CoreMedia case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList category="coremedia" count="2" />
      </div>
      <ContactPerson
        headline="Entrust your CoreMedia project to our experienced hands."
        text="No challenge is too big for our specialists to tackle. Talk to us today about your needs and requirements."
        contactEmail="coremedia@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Nicole Reske, Unit Lead CoreMedia"
      >
        <GatsbyImage
          image={images.nicole.childImageSharp.gatsbyImageData}
          alt="Nicole Reske"
        />
      </ContactPerson>
    </Layout>
  );
};

export default CoremediaPartnership;
